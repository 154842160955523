import React from 'react'
export default ({ size, stroke }) => (
	<svg
		id="emv-chip"
		aria-hidden="true"
		focusable="false"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 490"
		alt="EMV Chip"
		width={size ? size : 'auto'}
	>
		<path
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			d="M470.6,10
	c17.8,0,31.4,13.6,31.4,31.3v407.3c0,17.8-13.6,31.3-31.4,31.3H41.4C23.6,480,10,466.4,10,448.7V41.3C10,23.6,23.6,10,41.4,10H470.6
	"
		/>
		<line
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="10"
			y1="370.3"
			x2="155.8"
			y2="370.3"
		/>
		<line
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="10"
			y1="182.3"
			x2="153.8"
			y2="182.3"
		/>
		<line
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="361.5"
			y1="370.3"
			x2="502"
			y2="370.3"
		/>
		<line
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="10"
			y1="276.3"
			x2="175.8"
			y2="276.3"
		/>
		<line
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="341.4"
			y1="276.3"
			x2="502"
			y2="276.3"
		/>
		<line
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="10"
			y1="77.9"
			x2="193.9"
			y2="77.9"
		/>
		<line
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="256"
			y1="10"
			x2="256"
			y2="57"
		/>
		<path
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			d="M502,79.9l-174.8,0
	c-18.8-14.6-41.9-23-68-23c-59.7,0-107.8,46-107.8,102.4c0,39.7,24.1,74.2,58.6,90.9c-34.5,16.7-58.6,51.2-58.6,90.9
	c0,56.4,48.2,102.4,107.8,102.4s106.8-46,106.8-102.4c0-39.7-24.1-74.2-58.6-90.9c26.2-12.5,47.1-36.6,54.4-64.8l140.3,0.3V79.9z"
		/>
		<line
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '20'}
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="256"
			y1="443.4"
			x2="256"
			y2="480"
		/>
	</svg>
)
