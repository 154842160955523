import React from 'react'
export default ({ size, stroke }) => (
	<svg
		id="emv-contactless"
		aria-hidden="true"
		focusable="false"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		alt="EMV Contactless"
		width={size ? size : 'auto'}
	>
		<path
			fill="none"
			stroke="currentColor"
			strokeWidth={stroke ? stroke : '50'}
			strokeLinecap="round"
			d="M372.4,26c82.2,142.3,82.2,317.7,0,460 M271.2,76.6
	c64.1,111,64.1,247.8,0,358.8 M174.6,123.1c46.3,81.1,46.3,181.1,0,262.2 M78,173.2c33.5,50.7,33.5,114.9,0,165.6"
		/>
	</svg>
)
