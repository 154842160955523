import React from 'react'
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox'
import { Formik } from 'formik'
import { Lottie } from '@crello/react-lottie'
import * as animationCheckbox from '../../animations/checkbox.json'
import assign from 'lodash.assign'
import tw, { css, styled } from 'twin.macro'

/** Page components */
import { flat, validationSwitch, validationSchema } from '../form/utils'
import AddressSection from './AddressSection'
/** Hooks */
import useCart from '../../hooks/useCart'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
/** Styles with Emotion & Tailwindcss */
import {
	Label,
	FieldRow,
	FieldWrap,
	Required,
	Card,
	Input,
	Helper,
} from '../form/styles'
const Button = styled.button`
	${tw`flex bg-blue-500 hover:bg-blue-400 font-display text-black text-base px-4 py-1 rounded`}
	${({ hidden }) => (hidden ? tw`hidden` : ``)}
`
const CheckboxContainer = styled(CustomCheckboxContainer)`
	${tw`flex items-center justify-center`}
	input {
		${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap `}
		clip: rect(0 0 0 0);
		clippath: inset(50%);
	}
`

export default function CheckoutPayment(props) {
	const {
		isPickUp,
		setIsPaymentOpen,
		setPayment,
		shipping,
		setBilling,
		billing,
		setShipping,
		payment,
		setSameAsShipping,
		sameAsShipping,
	} = props
	const stripe = useStripe()
	const elements = useElements()
	const { items } = props.node
	// const { addShipping, paymentMethod } = useCart()
	/** Map Form Values - create empty [key]:value pair for each item */
	const valuesMapped = flat(items).map(item => ({ [item.inputName]: '' }))
	const validationMapped = flat(items).map(item => ({
		[item.inputName]: validationSwitch(item.inputName, item.validationText),
	}))
	/** New Object from mapped Array */
	const valuesObject = assign({}, ...valuesMapped)
	const validationObject = assign({}, ...validationMapped)
	const setAddress = values => ({
		firstName: values.firstName || '',
		lastName: values.lastName || '',
		email: values.email || '',
		phone: values?.phone || '',
		streetAddress: values.streetAddress || '',
		city: values.city || '',
		state: values.state || '',
		postCode: values.postCode || '',
		country: 'Australia',
	})
	const [cardErr, setCardErr] = React.useState('')
	return (
		<>
			<Formik
				initialValues={valuesObject}
				validationSchema={validationSchema(validationObject)}
				onSubmit={async (values, { setSubmitting, setValues }) => {
					try {
						setSubmitting(true)
						sameAsShipping
							? setBilling(shipping)
							: setBilling({
									firstName: values.firstName,
									lastName: values.lastName,
									email: values.email,
									phone: values?.phone,
									address: {
										fullAddress: `${values.streetAddress}, ${values.city} ${values.state} ${values.postCode}, Australia`,
										line1: values.streetAddress,
										line2: null,
										city: values.city,
										state: values.state,
										postalCode: values.postCode,
										country: 'Australia',
									},
							  })
						// https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
						const { source, error } = await stripe.createSource(
							elements.getElement(CardElement), // properties from CardElement
							{
								type: 'card',
								currency: 'aud',
								owner: {
									address: {
										city: values.city,
										country: 'Australia',
										line1: values.streetAddress,
										line2: null,
										postal_code: values.postCode,
										state: values.state,
									},
									email: values.email,
									name: `${values.firstName} ${values.lastName}`,
									phone: values?.phone,
								},
							}
						)
						if (error) throw new Error(error)
						setPayment(source)
						setSubmitting(false)
						return setIsPaymentOpen(false)
					} catch (e) {
						return setCardErr('Check your card details')
					}
				}}
				render={({
					handleSubmit,
					validateForm,
					setValues,
					setTouched,
					errors,
					touched,
					values,
				}) => (
					<form onSubmit={handleSubmit}>
						{!isPickUp && (
							<FieldRow>
								<FieldWrap>
									<Label htmlFor={'same-as-shipping'}>
										Address same as Shipping
										<Required>*</Required>
									</Label>

									<div css={tw`flex items-center my-2 text-base`}>
										<CheckboxContainer
											css={tw`w-6 h-6 cursor-pointer mx-2 bg-white rounded-sm`}
											onClick={() => {
												setSameAsShipping(sameAsShipping ? false : true)
											}}
											onKeyDown={() => {
												setSameAsShipping(sameAsShipping ? false : true)
											}}
										>
											<CustomCheckboxInput
												id="same-as-shipping"
												checked={sameAsShipping}
												name="same-as-shipping"
												type="checkbox"
											/>
											<Lottie
												playingState={'playing'}
												speed={1}
												direction={sameAsShipping ? 1 : -1}
												width={24}
												height={24}
												className="lottie-container checkbox"
												config={{
													animationData: animationCheckbox.default,
													loop: false,
													autoplay: true,
													renderer: 'svg',
												}}
												aria-hidden
											/>
										</CheckboxContainer>
										Yes
									</div>
								</FieldWrap>
							</FieldRow>
						)}
						{!sameAsShipping && (
							<AddressSection
								{...{ items, errors, touched, minimalist: Boolean(true) }}
							/>
						)}
						<FieldRow>
							<FieldWrap>
								<Label htmlFor={'card'}>
									Card details
									<Required>*</Required>
								</Label>
								<Card minimalist="true">
									<CardElement id="card" />
								</Card>
								{cardErr && <Helper>{`▲ ` + cardErr}</Helper>}
							</FieldWrap>
						</FieldRow>
						<Button
							type="button"
							onClick={() => {
								if (sameAsShipping) {
									return setValues({
										city: shipping.address.city,
										email: shipping.email,
										firstName: shipping.firstName,
										lastName: shipping.lastName,
										phone: shipping?.phone,
										postCode: shipping.address.postalCode,
										state: shipping.address.state,
										streetAddress: shipping.address.line1,
									})
										.then(() =>
											setTouched({
												city: true,
												email: true,
												firstName: true,
												lastName: true,
												phone: true,
												postCode: true,
												state: true,
												streetAddress: true,
											})
										)
										.then(() => handleSubmit())
								}
								return handleSubmit()
							}}
						>
							Confirm
						</Button>
					</form>
				)}
			/>
		</>
	)
}
