// AddressSection.js
import React from 'react'
import PropTypes from 'prop-types'
import FormFieldGroup from '../form/FormFieldGroup'
import FormFieldSingle from '../form/FormFieldSingle'

const AddressSection = ({ items, errors, touched, minimalist }) => (
	<>
		{items.map((item, index) => {
			// console.log(item)
			if (item.items) {
				const { title, items } = item
				return (
					<FormFieldGroup
						key={title + index}
						{...{ items, errors, touched, minimalist }}
					/>
				)
			} else {
				return (
					<FormFieldSingle
						key={item.inputName + index}
						{...{ item, errors, touched, minimalist }}
					/>
				)
			}
		})}
	</>
)
AddressSection.propTypes = {
	items: PropTypes.array,
	errors: PropTypes.object,
	touched: PropTypes.object,
	minimalist: PropTypes.bool,
}

export default AddressSection
