import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import tw, { css, styled } from 'twin.macro'

import { Global } from '@emotion/react'
/** Page components */
import Layout from '../../../components/layout'
import Meta from '../../../components/meta'
import CheckoutForm from '../../../components/checkout/CheckoutForm' /** Styles */
import useCart from '../../../hooks/useCart'
/** isBrowser */
const isBrowser = typeof window !== 'undefined'
const GlobalStyles = () => (
	<Global
		styles={css`
			.quantity-button[data-reach-menu-button] {
				${tw`inline-flex items-center`}
				${tw`border border-gray-600 rounded bg-gray-100 text-gray-600 text-xs py-1 px-1 leading-none`}
				outline: 0;
				&:hover,
				&:focus {
					outline: 0;
					box-shadow: 0 0 0 3px rgba(88, 200, 223, 0.85);
				}
				span {
					${tw`pl-2 pr-1 font-semibold`}
				}
			}
			.quantity-options[data-reach-menu-list] {
				${tw`border border-gray-600 rounded bg-gray-100 text-gray-600 text-sm py-2 px-1 my-2 shadow-lg`}
				outline: 0;
				&:hover,
				&:focus {
					outline: 0;
					box-shadow: 0 0 0 3px rgba(88, 200, 223, 0.85);
				}
				[data-reach-menu-item] {
					${tw`rounded-sm py-px px-0 hover:bg-gray-50 cursor-pointer`}
					outline: 0;
					&:hover,
					&:focus {
						outline: 0;
						box-shadow: 0 0 0 3px rgba(88, 200, 223, 0.85);
					}
				}
			}
		`}
	/>
)
const Wrapper = tw.div`bg-gray-100 py-4 md:py-8 px-2 md:px-4`
const Container = tw.section`max-w-7xl mx-auto sm:px-6 lg:px-40`
const Heading1 = tw.h1`text-gray-800 text-4xl font-semibold font-display mb-2 md:mb-4 pt-4 md:pt-8`

export const CheckoutPage = () => {
	const { page } = useStaticQuery(graphql`
		query checkoutPageQuery {
			page: allContentfulPage(filter: { slug: { eq: "checkout" } }) {
				edges {
					node {
						slug
						seoTitle
						seoKeywords
						seoDescription
						form {
							title
							description
							endpoint
							items {
								... on ContentfulFormGroup {
									title
									items {
										label
										inputId
										inputName
										inputType
										width
										options
										inputAutocomplete
										inputPlaceholder
										inputRequired
										helpText
										validationText
										icon
										textareaRows
										textareaCols
									}
								}
							}
						}
					}
				}
			}
		}
	`)
	const {
		slug,
		seoTitle,
		seoKeywords,
		seoDescription,
		form,
	} = page.edges[0].node

	const { items, total } = useCart()
	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring checkout steps - 2. Checkout page
		 * */
		let isFresh = true
		if (isBrowser && window.dataLayer && total > 1) {
			const products = items.map(
				({ name, price, sku, quantity, brand, category }) => ({
					name,
					id: sku,
					price: Number(price / 100).toFixed(2) * 1,
					quantity,
					brand,
					category,
				})
			)
			window.dataLayer.push({
				event: 'checkout2',
				ecommerce: {
					checkout: {
						actionField: { step: 2 },
						products,
					},
				},
			})
		}
		return () => (isFresh = false)
	})
	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			/>
			<GlobalStyles />
			<Wrapper>
				<Container>
					<Heading1>{seoTitle}</Heading1>
					<CheckoutForm node={form} locale="en-AU" currency="AUD" />
				</Container>
			</Wrapper>
		</Layout>
	)
}

export default CheckoutPage
