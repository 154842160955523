import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import assign from 'lodash.assign'
import { useMutation } from '@apollo/react-hooks'
import tw, { css, styled } from 'twin.macro'

/** Page components */
import { flat, validationSwitch, validationSchema } from '../form/utils'
import AddressSection from './AddressSection'
/** Styles with Emotion & Tailwindcss */
const Button = styled.button`
	${tw`flex bg-blue-500 hover:bg-blue-400 font-display text-black text-base px-4 py-1 rounded`}
	${({ hidden }) => (hidden ? tw`hidden` : ``)}
`
const CheckoutShipping = props => {
	const { setIsShippingOpen, setShipping, shipping, createConsumer } = props
	const { items } = props.node
	// const { addShipping, paymentMethod } = useCart()
	/** Map Form Values - create empty [key]:value pair for each item */
	const valuesMapped = flat(items).map(item => ({ [item.inputName]: '' }))
	const validationMapped = flat(items).map(item => ({
		[item.inputName]: validationSwitch(item.inputName, item.validationText),
	}))
	/** New Object from mapped Array */
	const valuesObject = assign({}, ...valuesMapped)
	const validationObject = assign({}, ...validationMapped)
	return (
		<Formik
			initialValues={valuesObject}
			validationSchema={validationSchema(validationObject)}
			onSubmit={(values, { setSubmitting }) => {
				let newStreetAddress = values.streetAddress
				newStreetAddress = newStreetAddress.split('/').join(' ')

				setIsShippingOpen(false)
				setSubmitting(true)
				setShipping({
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					phone: values?.phone,
					address: {
						fullAddress: `${newStreetAddress}, ${values.city} ${values.state} ${values.postCode}, Australia`,
						line1: values.streetAddress,
						line2: null,
						city: values.city,
						state: values.state,
						postalCode: values.postCode,
						country: 'Australia',
					},
				})
				// createConsumer({ variables: { ...shipping } })
				setSubmitting(false)
			}}
			render={({ handleSubmit, errors, touched }) => (
				<form onSubmit={handleSubmit}>
					<AddressSection
						{...{ items, errors, touched, minimalist: Boolean(true) }}
					/>
					<Button type="submit">Confirm</Button>
				</form>
			)}
		/>
	)
}
CheckoutShipping.propTypes = {
	createConsumer: PropTypes.func,
	setIsShippingOpen: PropTypes.func,
	setShipping: PropTypes.func,
	shipping: PropTypes.object,
	node: PropTypes.object,
}
export default CheckoutShipping
